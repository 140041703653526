<template>
  <FinalLayout subtext="What is YOUR store’s Shrink? Tap below to find out!">
    <template v-slot:rectangle>
      <ShrinkRectangle
        :showShrink="showShrinkAmount"
        @click="showShrinkAmount = false"
        :shrink="user"
      >
        <div class="question-mark">?</div>
      </ShrinkRectangle>
    </template>
    <template v-slot:footer>
      <SubmitButton @action="exitHandler" text="Finish" variant="contained" />
    </template>
  </FinalLayout>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitButton from "@/components/SubmitButton";
import routeNames from "@/router/routeNames";
import logo from "../assets/images/icons/logo-with-hand.svg";
import ShrinkRectangle from "../components/ShrinkRectangle";
import FinalLayout from "../components/FinalLayout";

export default {
  name: "Final",
  components: {
    FinalLayout,
    ShrinkRectangle,
    SubmitButton,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    showShrinkAmount: true,
    logo,
  }),
  methods: {
    exitHandler() {
      localStorage.removeItem("userId");
      this.$store.dispatch("resetUser");
      this.$store.dispatch("resetChosenCategoryIndexes");
      this.$store.dispatch("clearOpenGuessAnswer");
      this.$store.dispatch("clearError");
      this.$store.dispatch("resetAnswers");
      this.$router.push(routeNames.home);
    },
  },
};
</script>

<style scoped lang="scss">
.question-mark {
  font-family: "RobotoBold", sans-serif;
  font-weight: 700;
  font-size: 9.6rem;
}

@media (max-width: 1440px) {
  .question-mark {
    font-size: 12rem;
  }
}

@media (max-width: 991px) {
  .question-mark {
    font-size: 8rem;
  }
}
</style>
